import { Component, OnInit } from '@angular/core';
import { reportSearchParameters } from '../Reports/reportSearchParamters';
import { ToastrService } from 'ngx-toastr';
import { CommercialDocumentsService } from './Shared/commercial-documents.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { FormlabelsService } from '../shared/formlabels.service';


@Component({
  selector: 'app-commercial-documents',
  templateUrl: './commercial-documents.component.html',
  styleUrls: ['./commercial-documents.component.css']
})
export class CommercialDocumentsComponent implements OnInit {

  public searchParameters: reportSearchParameters;
  public userDetails: any;
  public requiredData: any = {};
  public minDate: any;
  public supplierShipmentFromDate: Date;
  public supplierShipmentToDate: Date;
  public commercilaDocumentsDetails: any;
  public dtOptions: DataTables.Settings = {};
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;
  public pageLabels: any = [];


  constructor(private toastrService: ToastrService, private commercialDocumentsService: CommercialDocumentsService, private formLabelService: FormlabelsService) {
    this.searchParameters = new reportSearchParameters;
  }

  ngOnInit() {
    this.getAllDataForSearch();

    this.dtOptions = {
      data: this.commercilaDocumentsDetails,
      pagingType: 'full_numbers',
      scrollY: '130px',
      scrollX: true
    };

    this.getTheSearchResult();

    this.getAllLabelsAndMessages();

  }


  getTheSearchResult() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.loading = true;
    if (this.searchParameters != null) {
      if (this.supplierShipmentFromDate != null && (this.supplierShipmentToDate == null || this.supplierShipmentToDate == undefined)) {
        this.toastrService.error(this.pageLabels[697], "Error");//Please select supplier shipment to date.[697]
      }

    } else {

      let currentDate = new Date();
      let lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

      this.searchParameters = new reportSearchParameters();
      this.searchParameters.destinationID = 0;
      this.searchParameters.supplierID = 0;
      this.searchParameters.poNumber = "NA";
      this.searchParameters.skuNumber = "NA";
      this.searchParameters.containerNumber = "NA";
      this.searchParameters.billOfLading = "NA";
      this.searchParameters.portOfOrigin = "NA";
      this.searchParameters.supplierShipmentFromDate = new Date(1900, 1, 1);
      this.searchParameters.supplierShipmentToDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, lastDay);
    }
    this.commercialDocumentsService.getAllDataForCommercialDocuments()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.loading = false;
            this.commercilaDocumentsDetails = result.data;
            this.toastrService.success(result.message, "Success", { timeOut: 1000 })
            console.log(result.data);

          } else if (result.isSuccessful == false) {
            this.loading = false;
            this.toastrService.clear();
            this.toastrService.error(result.message, "Error");
          } else {
            this.loading = false;
            this.toastrService.clear();
            this.toastrService.error(this.pageLabels[58], "Error");//Failed to connect to server.[58]
          };
        }
        catch (Error) {
          this.loading = false;
          //alert(Error.message);
        }
      });
  }

  //Added by Mahesh on 13-05-2020
  //To get all data required to serch commercial documents
  getAllDataForSearch() {
    this.commercialDocumentsService.getAllDataForSearch()
      .then((result) => {
        if (result.isSuccessful == true) {
          this.requiredData = result.data;
          console.log(result.data);
        } else if (result.isSuccessful == false) {
          this.toastrService.clear();
          this.toastrService.error(this.pageLabels[698], "Error");//Failed to load the required data.[698]
        } else {
          this.toastrService.clear();
          this.toastrService.error(this.pageLabels[58], "Error");//Failed to connect to server.[58]
        };

      })
  }


  clearAll(event: any) { //alert(event);
  }

  onDateSelect(event: any) {
    var temp = event;
    this.minDate = temp;
  }

  //Added by Kunal on 17/8/2020
  //To get all labels and messages from  m_locale_string_resources table.
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {

            this.pageLabels = [];
            //  this.pageMessages = [];

            for (let i = 0; i <= result.data.length - 1; i++) {

              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastrService.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastrService.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {

        }
      })
      .catch((err) => {

      })
  }
/*
  //Added - Shailesh
  downLoadDocument(documentObj): void {

    this.commercialDocumentsService.serviceGetThePreAssighedURL(documentObj).then((data) => {
      console.log(data);
      if (data.isSuccessful) {
        const url = data.data;
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', url);
        link.setAttribute('download', 'myfile1.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      else {
        this.toastrService.error(data.message, 'Error');
      }
    }).catch((error) => {


    });


  }
  */
//Added by Akshay
  downLoadDocument(documentObj): void {

    this.commercialDocumentsService.DownloadBOL(documentObj).then((data) => {
      console.log(data);

      if (data.isSuccessful) {
        var sampleArr = this.base64ToArrayBuffer(data.data.byteArray);
        this.saveByteArray(documentObj.UploadDocName, sampleArr, data.data.extension);

        // const url = data.data;
        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');
        // link.setAttribute('href', url);
        // link.setAttribute('download', 'myfile1.pdf');
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
      }
      else {
        this.toastrService.error(data.message, 'Error');
      }
    });


  }

   base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

 saveByteArray(reportName, byte, extn) {
  var blob = new Blob([byte], {type: `application/${extn.split('.')[1]}`});
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
};


}
