import { Injectable } from '@angular/core';
import { Global } from 'src/app/shared/global';
import { Headers, Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class CommercialDocumentsService {
  _global: Global;
  public userDetails: any;
  constructor(private global: Global, private http: Http) {
    this._global = global;
  }


  //Added by Mahesh on 13-05-2020
  //To get all data required to serch commercial documents
  public getAllDataForSearch(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Reports/GetAllDataForCommercialDocumentSearch", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch((respose) => {

        respose.json() as any
      }
      )
  }


  //Added by Mahesh on 14-05-2020
  //To get all data of commercial documents
  public getAllDataForCommercialDocuments(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Reports/GetCommercialDocumentResult", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch((respose) => {

        respose.json() as any
      }
      )
  }
/*
  public serviceGetThePreAssighedURL(documentObj):Promise<any>{
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    const bucketKey = documentObj.bolDocumentSystemFilename;
    return this.http.get("" + this._global.apiBaseURl + "Reports/GetPreAssignedURL?bucketKey="+bucketKey+"", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch((respose) => {

        respose.json() as any
      }
      )
  };
  */

  public DownloadBOL(documentObj):Promise<any>{
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
   
    const filePath=(documentObj.bolDocumentSystemFilename);
    
    // const bucketKey = documentObj.bolDocumentSystemFilename;

    return this.http.get("" + this._global.apiBaseURl + "Reports/GetPreAssignedURLDownloadBOL?bucketKey="+filePath+"", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch((respose) => {

        respose.json() as any
      }
      )
  };

}
